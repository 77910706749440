import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-screen-banner',
  standalone: true,
  imports: [],
  templateUrl: './screen-banner.component.html',
  styleUrl: './screen-banner.component.css'
})
export class ScreenBannerComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<ScreenBannerComponent>;
  constructor(
    private viewContainerRef: ViewContainerRef) {

    }


  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }


}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, LocalStorageService } from '../_services';
import { User } from '../_models';

@Injectable()
export class AuthGuard {
  private user: User;
  constructor(
    private authSvc: AuthenticationService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authSvc.isAlive()) {
      this.authSvc.logout('User session expired.');
      return false;
    }
    if (route.data.roles && !route.data.roles.includes(this.authSvc.user.role)) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { Role } from './_enums/roles';

/*
 * Criar módulo com rota e já adicionar no router automaticamente
 * ng generate module newSlick --route newslick --module app.module
 */
const routes: Routes = [
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./authentication/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
  },
  {
    path: 'ms-entra',
    loadComponent: () => import('./authentication/ms-entra/ms-entra.component').then((m) => m.MSEntraComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./authentication/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
  },
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    pathMatch: 'full',
  },
  {
    path: 'slicks',
    loadChildren: () => import('./slicks/slicks.module').then((m) => m.SlicksModule),
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ADMIN, Role.USER, Role.SUPPORT],
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      roles: [Role.CONTROL, Role.ADMIN],
    },
  },
  { path: 'testEdit', loadChildren: () => import('./test-edit/test-edit.module').then((m) => m.TestEditModule) },
  {
    path: 'singleVideo',
    loadChildren: () => import('./single-video/single-video.module').then((m) => m.SingleVideoModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      // onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import * as Sentry from "@sentry/angular";
import Hotjar from '@hotjar/browser';

const siteId = environment.hotjarCode;
const hotjarVersion = 6;

if (environment.environment !== 'local') {
  Sentry.init({
    dsn: "https://eb8bdf833996e13e346aa828813adfb9@o4507430189531136.ingest.de.sentry.io/4507430192349264",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://dev.slick.plus", "https://test.slick.plus", "https://beta.slick.plus"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.environment
  });
}

if (environment.production) {
  enableProdMode();
}

Hotjar.init(siteId, hotjarVersion);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

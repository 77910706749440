import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService, ToastService } from '../_services/';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private inj: Injector;

  constructor(inj: Injector) {
    this.inj = inj;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthenticationService);
    // const router = this.inj.get(Router);
    const toastService = this.inj.get(ToastService);
    const urlsToIgnore = [
      'https://slick-media.s3.amazonaws.com',
      'https://slick-videos.s3.amazonaws.com',
      'https://slick-media.s3.eu-west-1.amazonaws.com',
      'https://slick-videos.s3.eu-west-1.amazonaws.com',
      'https://slickplus-devtest-media-a1b2c3.s3.amazonaws.com',
      'https://slickplus-devtest-videos-a1b2c3.s3.amazonaws.com',
      'https://slickplus-devtest-media-a1b2c3.s3.eu-west-1.amazonaws.com',
      'https://slickplus-devtest-videos-a1b2c3.s3.eu-west-1.amazonaws.com',
      'https://slickplus-app-media-a1b2c3.s3.amazonaws.com',
      'https://slickplus-app-videos-a1b2c3.s3.amazonaws.com',
      'https://slickplus-app-media-a1b2c3.s3.eu-west-1.amazonaws.com',
      'https://slickplus-app-videos-a1b2c3.s3.eu-west-1.amazonaws.com',
    ];

    const shouldIgnore = urlsToIgnore.some(function (str) {
      return request.url.includes(str);
    });

    if (auth.user && auth.user.token && !shouldIgnore) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.user.token}`,
        },
      });
    }
    toastService.loader.next(true);
    return next.handle(request).pipe(
      tap(() => {
        toastService.loader.next(false);
      }),
      catchError((response) => {
        toastService.loader.next(false);
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401 && response.error?.message === 'Invalid token') {
            auth.logout('You have been logged out');
          } else if (response.status !== 400) {
            toastService.queue.next({
              message: response.error.message,
              duration: { duration: 5000 },
              panelClass: 'slick-custom-snackbar-error',
            });
          }
        }
        return throwError(response.error);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollPositionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}
  setScrollPosition(position: boolean) {
    this.scrollPositionSubject.next(position);
  }
  getScrollPosition(): Observable<boolean> {
    return this.scrollPositionSubject.asObservable();
  }
}

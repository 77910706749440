import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastService, AuthenticationService } from '../_services';

@Injectable()
export class HandleErrorNotification {
  private inj: Injector;
  constructor(
    inj: Injector,
  ) {
    this.inj = inj;
  }

  ErrorNotification(err): Observable<any> {
    let errMsg = '';
    if (err.error instanceof ErrorEvent) {
      errMsg = err.error.message;
    } else {
      errMsg = `${err.error} - ${err.message}`;
    }
    const toastService = this.inj.get(ToastService);
    toastService.queue.next({
      message: errMsg,
      panelClass: 'slick-custom-snackbar-error'
    });
    if (err.statusCode == 401) {
      const authSvc = this.inj.get(AuthenticationService);
      authSvc.logout();
    }

    throw new Error(errMsg);
  }

}


import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadFeedService {
  private updateFeedSubject = new Subject<any>();
  constructor() {}
  setUpdateFeed(slick: any) {
    this.updateFeedSubject.next(slick);
  }
  getUpdateFeed(): Observable<any> {
    return this.updateFeedSubject.asObservable();
  }
}

import { Component, HostBinding, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, LocalStorageService, ToastService, UsersService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../_enums/roles';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ScreenBannerComponent } from './screen-banner/screen-banner.component';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRippleModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    CommonModule,
    ScreenBannerComponent,
    RouterLink,
    MatProgressSpinnerModule,
  ],
})
export class AuthenticationComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col flex-grow';
  passwordVisible = false;
  isLoading = false;
  msEntraURL = null;
  token: string;
  user: User;
  isLoadingResults = false;

  errorMessages = {
    required: 'This field is required',
    email: 'Please provide a valid email address',
    badEmailOrPassword: 'Email or password is incorrect',
  };

  protected onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authSvc: AuthenticationService,
    private toastService: ToastService,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private userService: UsersService
  ) {
    this.msEntraURL = this.createMSEntraURL();
  }

  ngOnInit(): void {
    this.authSvc.user$.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
      if (user) {
        if ([Role.CONTROL].includes(user.role)) {
          this.router.navigate(['/admin']);
          return;
        } else if (user.role === Role.SUPPORT) {
          this.router.navigate(['/slicks/my']);
          return;
        }
        this.router.navigate(['/slicks/feed']);
        return;
      }
    });
  }

  loginForm: FormGroup = this.fb.group({
    email: new FormControl<string | null>('', [Validators.required, Validators.email]),
    password: new FormControl<string | null>('', [Validators.required, Validators.minLength(1)]),
  });

  resetForm: FormGroup = this.fb.group({
    email: new FormControl<string | null>('', [Validators.required, Validators.email]),
  });

  async login(loginForm): Promise<void> {
    if (!this.loginForm.valid) {
      return;
    }
    this.isLoading = true;
    try {
      const loginResult = await lastValueFrom(this.authSvc.rxLogin(loginForm.email, loginForm.password));

      this.isLoadingResults = true;
      this.localStorage.remove('topics');
      this.localStorage.remove('slicks');
      this.localStorage.remove('slicksCount');
      this.localStorage.remove('favoritesCount');
      if (loginResult.token != '') {
        this.authSvc.setUser(loginResult);
        this.toastService.queue.next({
          message: 'Welcome!',
          duration: 1000,
          panelClass: 'slick-custom-snackbar-success',
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message.includes('Invalid email or password')) {
          this.loginForm.get('email').setErrors({ badEmailOrPassword: true });
          this.loginForm.get('password').setErrors({ badEmailOrPassword: true });
        } else if (e.message.includes('Email is invalid')) {
          this.loginForm.get('email').setErrors({ email: true });
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  errors(ctrl: any): string[] {
    return ctrl.errors ? Object.keys(ctrl.errors) : [];
  }

  reset(resetForm): void {
    this.isLoadingResults = true;
    if (!this.resetForm.valid) {
      return;
    }
    this.authSvc.rxLogin(resetForm.email, resetForm.password).subscribe((data) => {
      if (data.token != '') {
        this.toastService.queue.next({
          message: 'Welcome!',
          duration: 1000,
        });
      }
    });
  }

  clearErrors(event: KeyboardEvent): void {
    this.loginForm.get('email').setErrors(null);
    this.loginForm.get('password').setErrors(null);
  }

  loadingDone(): void {
    this.isLoadingResults = false;
  }

  createMSEntraURL() {
    const authorize = new URL(environment.authority);

    authorize.searchParams.append('redirect_uri', environment.redirectUri);
    authorize.searchParams.append('scope', 'user.read openid profile offline_access');
    authorize.searchParams.append('client_id', environment.clientId);
    authorize.searchParams.append('response_type', 'code');
    authorize.searchParams.append('response_mode', 'query');
    authorize.searchParams.append('prompt', 'select_account');

    return authorize.toString();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}

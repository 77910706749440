export const environment = {
  production: false,
  environment: 'development',
  cfdistVideosUrl: 'https://videos.dev.slick.plus/',
  serverUrl: 'https://devapi.slick.plus/',
  baseUrl: 'https://dev.slick.plus/',
  localStorageKey: 'slickUser',
  imageMimeType: 'image/jpeg',
  mediaDist: 'https://media.dev.slick.plus/',
  clientId: "f63e1773-6c47-4946-aba8-3184e902d10e", // 'Application (client) ID' of app registration in the Microsoft MsEntra admin center - this value is a GUI
  authority: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize", // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
  redirectUri: "https://dev.slick.plus/ms-entra", // Must be the same redirectUri as what was provided in your app registration.
  hotjarCode: 5075898
};

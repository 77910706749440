<div class="slick-login-container mx-auto flex min-h-screen w-full flex-row">
  <div class="w-full bg-white p-4 lg:w-[55%] lg:px-[6.625em] xl:w-[46.6%] xl:px-[6.625em] xl:py-20">
    <div class="flex flex-col gap-4">
      <div class="pb-4">
        <img
          class="my-10 w-[6.75rem] sm:my-10 lg:mb-10 lg:mt-16 xl:mt-0"
          src="../../assets/img/slick_plus_logo_colorful.svg"
          alt="Slick+ logo"
        />
        <div class="text-black text-2xl font-semibold leading-[3rem] lg:text-[2rem]">Welcome back</div>
        <div class="text-sm text-[#79747E] lg:text-base">One step away from your knowledge diffusion</div>
      </div>
      <form class="flex w-full flex-col gap-3" (ngSubmit)="this.login(loginForm.value)" [formGroup]="loginForm">
        <mat-form-field
          (keydown)="this.clearErrors($event)"
          appearance="outline"
          floatLabel="always"
          class="relative w-full"
        >
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput type="email" placeholder="Enter your email" />
          <mat-icon
            matSuffix
            *ngIf="!this.loginForm.get('email').valid && this.loginForm.get('email').touched"
            class="text-[--mat-form-field-error-text-color]"
            >error</mat-icon
          >
          <mat-error MatError class="absolute bottom-[-20px] left-[12px] w-full">
            <ng-container *ngFor="let error of errors(loginForm.get('email'))">
              {{ errorMessages[error] }} <br /> </ng-container
          ></mat-error>
        </mat-form-field>

        <mat-form-field
          (keydown)="this.clearErrors($event)"
          appearance="outline"
          floatLabel="always"
          class="relative w-full"
        >
          <mat-label>Password</mat-label>
          <input
            formControlName="password"
            matInput
            [type]="passwordVisible ? 'text' : 'password'"
            placeholder="Enter your password"
          />
          <mat-error MatError class="absolute bottom-[-20px] left-[12px] w-full">
            <ng-container *ngFor="let error of errors(loginForm.get('password'))">
              {{ errorMessages[error] }} <br /> </ng-container
          ></mat-error>
          <button
            class="mx-3"
            type="button"
            mat-icon-button
            matSuffix
            matRipple
            (click)="passwordVisible = !passwordVisible"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="!passwordVisible"
          >
            <mat-icon>{{ passwordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
        </mat-form-field>
        <div class="relative left-[-10px] top-[-15px] flex flex-row justify-between">
          <mat-checkbox
            class="example-margin authorization-page-checkbox text-sm font-normal text-[#79747E] lg:text-base"
            >Remember me</mat-checkbox
          >
          <span
            [routerLink]="'/forgot-password'"
            class="my-auto cursor-pointer text-right text-[.875rem] text-sm font-normal text-purple-200 hover:underline lg:text-base"
            >Forgot password?</span
          >
        </div>
        <div>
          <button class="w-full rounded-lg bg-purple-200 p-3.5 text-base font-semibold text-white" type="submit">
            <span *ngIf="!isLoading">Log in</span>
            <mat-spinner class="m-auto" diameter="24" *ngIf="isLoading"></mat-spinner>
          </button>
        </div>
      </form>
    </div>
    <div class="separator my-8 text-center text-base font-normal text-[#79747E]">or continue with</div>
    <div class="">
      <a href="{{ msEntraURL }}">
        <button class="flex w-full flex-row gap-4 rounded-[.625rem] border px-4 py-3 text-center">
          <img class="" src="../../assets/img/login/microsoft-entra-id-color_icon.svg" alt="" />
          <span class="text-[#79747E]">Continue with Microsoft</span>
        </button>
      </a>
    </div>
  </div>
  <app-screen-banner class="hidden lg:block"></app-screen-banner>
</div>

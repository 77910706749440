import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictDirective } from './directives/restrict.directive';
import { ImageSizePipe } from '../_pipes/image-size.pipe';



@NgModule({
  declarations: [RestrictDirective, ImageSizePipe],
  imports: [
    CommonModule
  ],
  exports: [RestrictDirective, ImageSizePipe]
})
export class SharedModule { }

import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ToastService {
  private notifications$ = new Subject<any>();
  private loader$ = new Subject<boolean>();

  get queue(): Subject<any> {
    return this.notifications$;
  }

  get loader(): Subject<boolean> {
    return this.loader$;
  }
}

<ng-template #template>
  <div
  class="hidden lg:flex bg-purple-200 lg:bg-[0%_-100%] bg-cover bg-[url('~src/assets/img/login/pattern.svg')] bg-no-repeat w-[60%] flex-col justify-end pb-[10%]"
  >
  <div class="mx-auto px-10 flex flex-col gap-6">
    <div class="text-white text-[2.5rem] leading-[3.75rem] font-bold text-center">
      Your knowledge diffusion just above you
    </div>
    <div class="text-white text-xl text-center">
      Dive into a world of exclusive tools designed to skyrocket your productivity and amplify your success. Join now
      for a game-changing experience!
    </div>
  </div>
  </div>
</ng-template>
